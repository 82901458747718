import React from 'react';

import { Waves, Heading, Paragraph, Collapsible } from 'components';

import { TRENDS } from './constants';

import styles from './styles.module.scss';

const Trends = () => (
  <div className={styles.wrapper}>
    <Waves />
    <div className={styles.container}>
      <div className={styles.left}>
        <Heading size="medium" color="white" className={styles.title}>
          Our data identified the key themes which are driving the future of the
          built environment.
        </Heading>
        <Paragraph color="blue" size="small" className={styles.description}>
          All speeches recorded in green screen and content created at
          RE:Connect are classified to enable consumers to navigate it easily
          and allow sponsors to establish category leadership.
        </Paragraph>
        <Paragraph color="blue" size="small" className={styles.description}>
          Other themes are available.
        </Paragraph>
        <a href="mailto:info@unissu.com">
          <Paragraph color="blue" size="small" className={styles.description}>
            Get in touch
          </Paragraph>
        </a>
      </div>
      <div className={styles.right}>
        <Collapsible singleSubItemPerLine items={TRENDS} />
      </div>
    </div>
  </div>
);

export default Trends;
