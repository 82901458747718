import metrikus from './assets/metrikus.png';
import airrated from './assets/airrated.png';
import haltian from './assets/haltian.png';
import proda from './assets/proda.png';
import navigatorcre from './assets/navigatorcre.png';
import smartrent from './assets/smartrent.png';
import brainbox from './assets/brainbox.png';
import propco from './assets/propco.png';
import xempla from './assets/xempla.png';
import mallcomm from './assets/mallcomm.png';

export const TRENDS = [
  {
    title: 'Technologies',
    items: [
      { text: 'Construction Technology' },
      {
        text: 'Artificial Intelligence in Real Estate',
        image: {
          src: brainbox,
          height: 13,
          width: 124,
          href:
            'https://www.unissu.com/proptech-company/brainbox-ai?utm_source=brainbox-ai&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      {
        text: 'Internet of Things (IOT)',
        image: {
          src: smartrent,
          height: 20.16,
          width: 125,
          href:
            'https://www.unissu.com/proptech-company/smartrent?utm_source=smartrent&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      {
        text: 'Digital Twins & Smart Buildings',
        image: {
          src: metrikus,
          height: 21,
          width: 89,
          href:
            'https://www.unissu.com/proptech-company/metrikus?utm_source=metrikus&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      { text: 'Pillars of Digital Transformation' },
      {
        text: 'The Future of Residential',
        image: {
          src: propco,
          height: 33,
          width: 91.5,
          href:
            'https://www.unissu.com/proptech-company/propco?utm_source=propco&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      { text: 'The Future of Commercial' }
    ]
  },
  {
    title: 'User Focuses',
    items: [
      {
        text: 'Healthy Buildings',
        image: {
          src: airrated,
          height: 16,
          width: 88,
          href:
            'https://www.unissu.com/proptech-company/airrated?utm_source=airated&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      { text: 'Smart Cities & Urban Mobility' },
      {
        text: 'Modern Workplaces & Tenant Experience',
        image: {
          src: haltian,
          height: 20.25,
          width: 83,
          href:
            'https://www.unissu.com/proptech-company/haltian?utm_source=haltian&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      {
        text: 'Property & Facilities Management for a Digital Age',
        image: {
          src: xempla,
          height: 24.8,
          width: 98.4
        }
      },
      {
        text: 'Asset Management',
        image: {
          src: proda,
          height: 26.78,
          width: 97,
          href:
            'https://www.unissu.com/proptech-company/proda?utm_source=proda&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      {
        text: 'Sustainability & Energy Efficiency'
      }
    ]
  },
  {
    title: 'Digital Transformation',
    items: [
      {
        text: 'Real Estate & Data',
        image: {
          src: navigatorcre,
          height: 19.08,
          width: 124,
          href:
            'https://www.unissu.com/proptech-company/navigatorcre?utm_source=navigatorcre&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      },
      { text: 'Talent / People' },
      { text: 'VC Funding' },
      { text: 'FinTech & PropTech' },
      { text: 'Emerging Business Models' },
      {
        text: 'The Future of Retail',
        image: {
          src: mallcomm,
          height: 22.4,
          width: 99.2,
          href:
            'https://www.unissu.com/proptech-company/mallcomm?utm_source=mallcomm&utm_medium=globalproptech&utm_campaign=connect2sponsor'
        }
      }
    ]
  }
];
